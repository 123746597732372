import React from "react"
import styled from "styled-components"

const Indented = styled.div`
  margin-left: 20px;
`

const GuardianshipFAQAccordion = () => {
  return (
    <div id="accordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <button
              class="btn btn-link"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              什么是监护人？
            </button>
          </h5>
        </div>

        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div class="card-body">
            监护人是父母指定在英国为其子女负责的个人或公司。他们代表父母行事，确保孩子在英国学习期间的健康和学业进步。
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              为什么我需要指定监护人？
            </button>
          </h5>
        </div>
        <div
          id="collapseTwo"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordion"
        >
          <ul class="card-body">
            <li>
              英国边境管理局和英国寄宿学校要求每个 16
              岁以下的学生都有一名居住在英国的教育监护人。
            </li>
            <li>学校通常也要求 16 岁以上的学生有英国监护人。</li>
            <li>
              父母需要有人在以下情况下对他们的孩子负责：
              <Indented>- 在学校：确保他们的学习和学校生活顺利进行。</Indented>
              <Indented>
                -
                校外：在旅行期间以及周末和假期期间，确保他们安全、受到照顾并感到舒适。
              </Indented>
            </li>
            <li>
              像 Study Links
              等经验丰富、关怀备至的监护公司将负责处理实际细节和其他问题，让家长高枕无忧，学生也可以享受学习。
            </li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Study Links 能为我们做什么？
            </button>
          </h5>
        </div>
        <div
          id="collapseThree"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordion"
        >
          <ul class="card-body">
            <li>
              提供 24/7
              全天候紧急联系电话，并在任何困难情况下迅速、专业地为学生和家长采取行动。
            </li>
            <li>
              对于学生或家长可能存在的任何疑问、担忧和不确定性，我们可随时联系并提供建议。
            </li>
            <li>
              与学生、他们的父母、他们的代理人和学校保持联系，以确保一切顺利。
            </li>
            <li>
              为您孩子的校外生活匹配一个热情、友好的寄宿家庭，这些寄宿家庭经过仔细培训和检查，符合高标准。
            </li>
            <li>安排在英国往返学校、寄宿家庭、机场之间的安全行程。</li>
            <li>安排行动计划并提供建议，帮助学生学习和制定未来计划。</li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFour">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Study Links 如何运作？
            </button>
          </h5>
        </div>
        <div
          id="collapseFour"
          class="collapse"
          aria-labelledby="headingFour"
          data-parent="#accordion"
        >
          <div class="card-body">
            <div>
              Study Links
              是一家由家族经营的公司，所有成员在一个团队中工作，为您的家庭提供监护服务。我们的一些团队成员在办公室工作，一些成员将亲自到学校拜访学生。
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFive">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              我们该如何联系 Study Links？
            </button>
          </h5>
        </div>
        <div
          id="collapseFive"
          class="collapse"
          aria-labelledby="headingFive"
          data-parent="#accordion"
        >
          <ul class="card-body">
            <li>
              联系我们的最佳方式是通过电子邮件：{" "}
              <a href={"mailto:office@studylinks.co.uk"}>
                office@studylinks.co.uk
              </a>
            </li>
            <li>
              您可以在英国时间周一至周五晚上 9 点至 5 点的办公时间内致电{" "}
              <a href="tel: +44 0127 4679710">+44 (0)1274 679710</a>
            </li>
            <li>
              我们的紧急电话是{" "}
              <a href="tel: +44 7884 101679">+44 7884 101679</a>
            </li>
            <li>如果办公室不营业但您立即需要帮助，请拨打紧急电话。</li>
            <li>
              我们可能会通过 Whatsapp
              和微信与您联系，但我们的主要沟通渠道是电子邮件。请经常检查您的电子邮件以获取重要信息。
            </li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingSix">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              Study Links 可以在任何学校/大学提供监护服务吗？
            </button>
          </h5>
        </div>
        <div
          id="collapseSix"
          class="collapse"
          aria-labelledby="headingSix"
          data-parent="#accordion"
        >
          <div class="card-body">
            <div>
            是的，Study Links 可在整个英国提供监护服务。我们在全英国各地都有员工和寄宿家庭，可在任何紧急情况下为您提供帮助。



            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingSeven">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              我们应该如何为孩子在英国学习做准备？

            </button>
          </h5>
        </div>
        <div
          id="collapseSeven"
          class="collapse"
          aria-labelledby="headingSeven"
          data-parent="#accordion"
        >
          <div class="card-body">
            <div>
            Study Links 为家长和学生提供有关如何为英国学习做准备的详细指南。我们作为您的监护人在这里为您的家庭提供支持。




            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingEight">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              Study Links 可以在假期提供住宿吗？
            </button>
          </h5>
        </div>
        <div
          id="collapseEight"
          class="collapse"
          aria-labelledby="headingEight"
          data-parent="#accordion"
        >
          <div class="card-body">
            <div>
            可以。Study Links 在学校假期期间为学生提供家庭寄宿。如果需要，Study Links 也可以安排假期营。



            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingNine">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              Study Links 可以为监护学生提供哪些额外服务？

            </button>
          </h5>
        </div>
        <div
          id="collapseNine"
          class="collapse"
          aria-labelledby="headingNine"
          data-parent="#accordion"
        >
          <ul class="card-body">
            <li>
            Study Links 为监护学生及其家庭提供许多额外服务。完整的详细信息将在注册时发送。这些额外服务包括：

            </li>
            <li>私人辅导</li>
            <li>
            专业大学申请建议，包括牛津剑桥准备、医学、法律
            </li>
            <li>为家庭安排英国旅行</li>
            <li>机场陪同服务</li>
            <li>机场接送</li>
            <li>家庭寄宿</li>
            <li>
            度假营（例如夏令营；复活节营；复习营）
            </li>
            <li>集成研讨会</li>
            <li>学习技巧课</li>
            <li>英语会话班</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default GuardianshipFAQAccordion
