import React from "react"
import Layout from "../layout/layout"
import ReactHtmlParser from "react-html-parser"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import GuardianshipFAQAccordion from "../components/Accordions/GuardianshipFAQ"
import GuardianshipVideo from "../video/Guardianship.mp4"
import Video from "../components/Video/LargeVideo"
import ButtonCardItem from "../components/ButtonCards/NewTabButton"
import { CardGrid, CardWrapper } from "../components/ButtonCards/CardElements"
import { guardianshipGif as Gif } from "../images/"
import {
  GuardianshipTestimonialCarousel,
  InsertedTestimony,
} from "../components/Testimonials"
import {
  guardianshipApply,
  guardianshipContact,
  guardianshipBanner,
} from "../images/"
import PricingPDF from "../PDF/guardianshipPrice.pdf"
import BrochurePDF from "../PDF/guardianshipBrochure.pdf"
import { CentreWrapper, GreenButton } from "../components/ButtonElement"
import SEO from "../components/seo"

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const GifContainer = styled.img`
  width: 100%;
  height: auto;
  align-self: center;

  @media (min-width: 2000px) {
    width: 1600px;
  }
`

const FeeButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

const sidebar = [
  {
    to: "educationalGuardian",
    title: "教育监护人",
  },
  {
    to: "ourService",
    title: "我们的服务",
  },
  {
    to: "fees",
    title: "费用",
  },
  {
    to: "apply",
    title: "申请",
  },
  {
    to: "faq",
    title: "常见问题",
  },
]

const FadeH1 = ({ children }) => {
  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <Fade>
        <h1>{children}</h1>
      </Fade>
      <br />
    </div>
  )
}

const Section = styled.div`
  margin: 40px 230px;
  max-width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin: 40px 100px;
  }

  @media (max-width: 900px) {
    margin: 40px 50px;
  }

  @media (max-width: 740px) {
    margin: 9px;
  }
`

export default function Guardianship({ data }) {
  const html = data.allWpPage.edges
  const overview = ReactHtmlParser(html[2].node.content)
  const ourService = ReactHtmlParser(html[0].node.content)
  const fees = ReactHtmlParser(html[1].node.content)
  console.log(fees)
  return (
    <div>
      <Layout
        sidebar={sidebar}
        pageTitle="监护"
        background={guardianshipBanner}
        pageDescription="我们提供独立专家咨询意见和全天候紧急支持，为学生提供实际支持，让家长绝对放心。"
      >
        <SEO
          title="Guardianship | UK Education | Schools | University | Boarding Schools"
          description="Study Links provide guardianship for their international students. We offer 4 guardianship packages which can be further tailored to meet the needs of your family."
        />
        <Section id="educationalGuardian">
          <FadeH1>什么是教育监护人？</FadeH1>
          <VideoContainer>
            <Video
              videoSrcURL={GuardianshipVideo}
              videoTitle="Welcome to Studylinks"
            />
          </VideoContainer>
        </Section>
        <Section id="ourService">
          <FadeH1>我们的服务</FadeH1>
          {ourService.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <br />
          <GifContainer src={Gif} />
          <br />
          <br />
        </Section>
        <Section id="fees">
          <FadeH1>费用</FadeH1>
          {fees.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <Fade>
            <FeeButtonsWrapper>
              <GreenButton href={BrochurePDF} target="_blank">
                手册 PDF
              </GreenButton>
              <GreenButton href={PricingPDF} target="_blank">
                定价 PDF
              </GreenButton>
            </FeeButtonsWrapper>
          </Fade>
        </Section>
        <Section id="apply">
          <FadeH1>申请</FadeH1>
          <br />
          <CentreWrapper>
            <CardWrapper>
              <ButtonCardItem
                path="https://studylinks.force.com/Studylinks/s/guardianshipapplication"
                label="申请"
                text="点击此处申请监护"
                src={guardianshipApply}
              />
            </CardWrapper>
            <CardWrapper>
              <ButtonCardItem
                path="/contact"
                label="留言"
                text="有什么问题吗？给我们留言"
                src={guardianshipContact}
              />
            </CardWrapper>
          </CentreWrapper>
        </Section>
        <Section id="faq">
          <FadeH1>常见问题</FadeH1>
          <GuardianshipFAQAccordion />
        </Section>
        <GuardianshipTestimonialCarousel />
      </Layout>
    </div>
  )
}

export const STUDYLINKS_GUARDIANSHIP = graphql`
  query studylinks_guardianship {
    allWpPage(
      filter: {
        slug: {
          in: [
            "api-studylinks-simplified-whatisaneducational"
            "api-studylinks-simplified-guardianship"
            "api-studylinks-simplified-guardianshipfees"
          ]
        }
      }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`
